import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
import formerGeometriske from "../../../static/img/former/former-geometriske.png";
import formerOrganiske1 from "../../../static/img/former/former-organiske1.png";
import formerOrganiske2 from "../../../static/img/former/former-organiske2.png";
import formerAvrundetRektangulaere1 from "../../../static/img/former/former-avrundet-rektangulaere1.png";
import formerAvrundetRektangulaere2 from "../../../static/img/former/former-avrundet-rektangulaere2.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Geometriske`}</h2>
    <p>{`Sirkelen er vår form. Den kan brukes hel, i utsnitt av halv og kvart sirkel. Sirkelen kan også være utgangpunkt for bildeformater eller sammen med illustrasjoner.`}</p>
    <img src={formerGeometriske} alt="Fire illustrasjoner av hvordan sirkelen kan brukes i design" className="sb1ds-layout__spacing-bottom--md" />
    <h2>{`Organiske`}</h2>
    <p>{`Vi har også en mer organisk form bygget opp av geometriske sirkler. Denne skal brukes for å myke opp uttrykket der det er behov for det.`}</p>
    <p>{`OBS! Ikke overdriv bruken av den organiske formen sammen med andre organiske elementer som illustrasjon.`}</p>
    <img src={formerOrganiske1} alt="Illustrasjon av hvordan organisk form kan brukes i design" className="sb1ds-layout__spacing-bottom--md" />
    <img src={formerOrganiske2} alt="Illustrasjon av hvordan organisk form kan brukes i design" className="sb1ds-layout__spacing-bottom--md" />
    <h2>{`Avrundet rektangulære`}</h2>
    <p>{`Den visuelle identiteten gir også rom for å bruke firkantede former som har ett eller flere avrundede hjørner.`}</p>
    <p>{`Rektangulære former skal avrundes ved hjelp av en sirkel på det smaleste punktet i rektangelet, slik som vist i eksempelet.`}</p>
    <img src={formerAvrundetRektangulaere1} alt="Illustrasjon av hvordan sirkelen kan brukes for å avrunde hjørner" className="sb1ds-layout__spacing-bottom--md" />
    <h3>{`3 avrundingsnivåer`}</h3>
    <ol>
      <li parentName="ol">{`Avrunding basert på en sirkel som er like høy og bred som boksen den står i`}</li>
      <li parentName="ol">{`Halve bredden og høyden`}</li>
      <li parentName="ol">{`En kvart del av høyden og bredden`}</li>
    </ol>
    <img src={formerAvrundetRektangulaere2} alt="Illustrasjon av tre avrundingsnivåer" className="sb1ds-layout__spacing-bottom--md" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      